import { invoiceStatus } from "../../configs/constants/global";
import * as actions from "../../configs/constants/invoiceConstants";
import {
  canApproveDeveloperInvoice,
  canApproveInvoice,
  canArchiveInvoice,
  canDeleteDeveloperInvoice,
  canDeleteInvoice,
  canEditDeveloperInvoice,
  canEditInvoice,
  canGenerateInvoice,
  canMarkInvoiceAsPaid,
  canRejectDeveloperInvoice,
  canRejectInvoice,
  canSendInvoiceForApproval,
  canSendInvoiceReminder,
  isClient,
  isPayAdmin,
} from "../auth";
import {
  approveInvoice,
  approvePayout,
  generateInvoice,
  markAsArchived,
  markAsPaid,
  markPayoutAsPaid,
  onApproveCSOPayout,
  onDeleteInvoice,
  onDeleteInvoiceBatch,
  onUnarchiveInvoice,
  rejectInvoice,
  sendInvoiceForApproval,
  sendReminder,
} from ".";
import { onUpdateInvoice, onUpdateInvoiceBatch } from "./onUpdateInvoice";

/**
 * Determine whether to show action or not depending on logged in User
 * @param {string} action constants used for the different actions in payments and payouts
 * @param {*} invoice invoice if payments action or invoices if payouts action
 * @returns {boolean} false | true
 */

// actions permissions

export const showAction = (action, invoice) => {
  const invoices = Array.isArray(invoice) ? invoice : [invoice];

  switch (action) {
    case actions.GENERATE_INVOICE_ACTION:
      return (
        canGenerateInvoice() &&
        invoices.every(
          (inv) =>
            !inv?.archived &&
            !inv.finalized &&
            !inv.last_sent_at &&
            !inv.paid &&
            inv.status === "approved",
        )
      );
    case actions.PAY_ACTION:
      return isClient() && invoices.every((inv) => !inv?.archived && inv.finalized && !inv.paid);
    case actions.EDIT_ACTION:
      return (
        canEditInvoice() &&
        invoices.every((inv) => !inv?.archived && !inv.paid && !inv.finalized && !inv.last_sent_at)
      );
    case actions.DELETE_ACTION:
      return (
        canDeleteInvoice() &&
        invoices.every((inv) => !inv?.archived && !inv.paid && !inv.finalized && !inv.last_sent_at)
      );
    case actions.MARK_AS_PAID_ACTION:
      return (
        canMarkInvoiceAsPaid() &&
        invoices.every((inv) => !inv?.archived && !inv.paid && (inv.finalized || inv.last_sent_at))
      );
    case actions.SEND_REMINDER:
      return (
        canSendInvoiceReminder() &&
        invoices.every((inv) => !inv?.archived && !inv.paid && (inv.finalized || inv.last_sent_at))
      );
    case actions.APPROVE_BATCH_ACTION:
      return (
        canApproveInvoice() &&
        isPayAdmin() &&
        invoices.every((inv) => !inv?.archived && inv.status !== "approved")
      );
    case actions.ARCHIVE_ACTION:
      return canArchiveInvoice() && invoices.every((inv) => !inv?.archived && !inv.paid);
    case actions.REJECT_ACTION:
      return (
        canRejectInvoice() &&
        invoices.every((inv) => !inv?.archived && !inv.paid && inv.status === invoiceStatus.PENDING)
      );
    case actions.APPROVE_ACTION:
      return (
        canApproveInvoice() &&
        invoices.every((inv) => !inv?.archived && !inv.paid && inv.status === invoiceStatus.PENDING)
      );
    case actions.SEND_FOR_APPROVAL_ACTION:
      return (
        canSendInvoiceForApproval() &&
        invoices.every(
          (inv) =>
            !inv?.archived &&
            !inv.paid &&
            (inv.status === invoiceStatus.DRAFT || inv.status === invoiceStatus.REJECTED),
        )
      );
    case actions.MARK_AS_PAID_BATCH_ACTION:
      return (
        canMarkInvoiceAsPaid() &&
        invoices.every(
          (inv) =>
            !inv?.archived &&
            !inv?.project?.archived &&
            inv?.status !== "draft" &&
            !inv?.paid &&
            !(inv?.payment_platform === "payoneer" && inv?.status === "approved"),
        )
      );

    case actions.EDIT_ACTION_BATCH:
      return (
        canEditDeveloperInvoice() &&
        invoices.every((inv) => !inv.archived && !inv.status !== "approved" && !inv.paid)
      );
    case actions.DELETE_ACTION_BATCH:
      return (
        canDeleteDeveloperInvoice() &&
        invoices.every(
          (inv) =>
            !inv?.archived && !inv.project.archived && !inv.status !== "approved" && !inv.paid,
        )
      );

    case actions.REJECT_PAYOUT_ACTION:
      return (
        canRejectDeveloperInvoice() &&
        invoices.every((inv) => !inv?.archived && inv.status === "draft")
      );
    case actions.APPROVE_CSO_PAYOUT_ACTION:
      return (
        canApproveDeveloperInvoice() &&
        invoices.every((inv) => !inv?.archived && inv.status === "draft")
      );

    case actions.UNARCHIVE_ACTION:
      return invoices.every((inv) => !inv.project.archived && inv?.archived);

    default:
      return null;
  }
};

/**
 * Determine what function to call depending on the action that been triggered
 * @param {string} action constants used for the different actions in payments and payouts
 * @param {*} data invoice if payments action or invoices if payouts action
 */
export const performAction = (action, data) => {
  switch (action) {
    case actions.GENERATE_INVOICE_ACTION: {
      generateInvoice(data.id);
      break;
    }
    case actions.MARK_AS_PAID_ACTION: {
      markAsPaid(data.id);
      break;
    }
    case actions.SEND_REMINDER: {
      sendReminder(data);
      break;
    }
    case actions.MARK_AS_PAID_BATCH_ACTION: {
      markPayoutAsPaid(data);
      break;
    }
    case actions.ARCHIVE_ACTION: {
      markAsArchived(data);
      break;
    }
    case actions.APPROVE_BATCH_ACTION: {
      approvePayout(data);
      break;
    }

    case actions.REJECT_ACTION:
    case actions.REJECT_PAYOUT_ACTION: {
      rejectInvoice(data);
      break;
    }

    case actions.SEND_FOR_APPROVAL_ACTION: {
      sendInvoiceForApproval(data.id);
      break;
    }

    case actions.APPROVE_ACTION: {
      approveInvoice(data.id);
      break;
    }

    case actions.EDIT_ACTION: {
      onUpdateInvoice(data);
      break;
    }

    case actions.EDIT_ACTION_BATCH: {
      onUpdateInvoiceBatch(data);
      break;
    }

    case actions.DELETE_ACTION: {
      onDeleteInvoice(data);
      break;
    }

    case actions.DELETE_ACTION_BATCH: {
      onDeleteInvoiceBatch(data);
      break;
    }

    case actions.APPROVE_CSO_PAYOUT_ACTION: {
      onApproveCSOPayout(data);
      break;
    }

    case actions.UNARCHIVE_ACTION: {
      onUnarchiveInvoice(data);
      break;
    }

    default:
      break;
  }
};
