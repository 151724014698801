/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                            External dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownToggle, Badge } from "reactstrap";
import styled from "styled-components";

import { CATEGORIES, EVENTS } from "../../../analytics/events";
import { trackEvent } from "../../../analytics/segment";
/* -------------------------- Internal dependencies ------------------------- */
import Icon from "../../../components/Icon";
import InvoiceHistory from "../../../components/InvoiceHistory";
import ReactTable from "../../../components/ReactTable";
import SummaryPlaceholder from "../../../components/SummaryPlaceholder/SummaryPlaceholder";
import {
  APPROVE_ACTION,
  ARCHIVE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  GENERATE_INVOICE_ACTION,
  MARK_AS_PAID_ACTION,
  REJECT_ACTION,
  SEND_FOR_APPROVAL_ACTION,
  SEND_REMINDER,
  UNARCHIVE_ACTION,
} from "../../../configs/constants/invoiceConstants";
import useDropdownPopper from "../../../hooks/useDropdownPopper";
import useRightNav from "../../../layouts/RightSideNav/useRightNav";
import {
  ENDPOINT_INVOICES,
  INVOICE_TYPE_COMMITMENT,
  INVOICE_TYPE_CREDIT_NOTE,
  INVOICE_TYPE_FINAL,
  INVOICE_TYPE_SALE,
} from "../../../utils/api";
import {
  canApproveInvoice,
  canArchiveInvoice,
  canCreateClientInvoices,
  canCreateCommitmentInvoice,
  canCreateCreditNoteInvoice,
  canCreateFinalInvoice,
  canCreateSaleInvoice,
  canDeleteInvoice,
  canEditInvoice,
  canMarkInvoiceAsPaid,
  canRejectInvoice,
  canSendInvoiceForApproval,
  canSendInvoiceReminder,
  canUnarchiveInvoice,
  isCMOrCSOAndHasProjectAcess,
} from "../../../utils/auth";
import { Reminder, tableData } from "../../../utils/invoiceUtils";
import ActionItem from "./components/ActionItem";
import DropdownActionItem from "./components/DropdownActionItem";
import PaymentStatus from "./components/PaymentStatus";
import PaymentFilters from "./components/PaymentFilters";
import { useInvoices } from "../../../context/InvoiceContext";
import { columnFilter, generateColumnsFromArray } from "../../../utils/helpers";
import { onCreateInvoice } from "../../../utils/invoiceUtils/onCreateInvoice";
import { showAction } from "../../../utils/invoiceUtils/invoiceActions";
import { getTableColumns } from "../../../utils/invoiceUtils/tableColumns";

/* --------------------------- Styles dependencies -------------------------- */
import { StyledButtonDropdown } from "./styles";

const invoiceType = {
  "sale": "Sale",
  "final": "Final",
  "credit_nota": "Credit Note",
  "commitment": "Commitment",
};

const Payments = (props) => {
  const {
    data: invoices,
    isSaving,
    count,
    onLoadMore,
    currentPage,
    project,
    setcreateAction,
    pageSize,
  } = props;
  const [open, setopen] = useState(null);
  const [checked, setChecked] = useState([]);
  const { open: openSideBar } = useRightNav();
  const { paymentColumns, paymentState, setPaymentColumns } = useInvoices();

  const { styles, attributes, setPopperElement, setReferenceElement, dropDownPortal } =
    useDropdownPopper();
  const { errors, isMakingRequest } = useSelector(({ Invoice }) => Invoice);

  const scope = project === undefined || project === null ? "sideBarPayment" : "project";
  const allIDs = invoices.filter(({ paid }) => !paid);

  const checkAll = () => {
    return setChecked((v) => {
      if (v.length === allIDs.length) {
        return [];
      }
      return allIDs;
    });
  };

  const checkItem = (item) => {
    let newArr = [];

    const hasItem = checked.filter((a) => a.id === item.id).length > 0;

    if (!hasItem) {
      newArr = [...checked, item];
    } else {
      newArr = checked.filter((a) => a.id !== item.id);
    }
    setChecked(newArr);
  };

  /* istanbul ignore next */
  const toggleAll = (state) => {
    const checkboxes = document.querySelectorAll("[name=check-it]");
    const checkedBoxMapped = Array.from(checkboxes)
      .filter((checkbox) => {
        const found = invoices.find((fp) => fp.id === +checkbox.id);
        return found && !found.paid;
      })
      .map((checkbox) => {
        checkbox.checked = state;
        const found = invoices.find((fp) => fp.id === +checkbox.id);
        return found;
      });

    if (state) {
      setChecked(checkedBoxMapped);
    } else {
      setChecked([]);
    }
  };

  const toggleAction = (invoiceId) => {
    setopen(open === invoiceId ? null : invoiceId);
  };

  const isChecked = checked.length !== 0 && checked.length === allIDs.length;

  const tableColumnsRaw = getTableColumns(
    paymentState.invoiceStatus[0],
    project,
    isChecked,
    checkAll,
  );

  const columnFilterFn = columnFilter(paymentColumns);
  const tableColumns = tableColumnsRaw.filter(columnFilterFn);
  const tableColumnsArr = generateColumnsFromArray(tableColumnsRaw);

  useEffect(() => {
    setPaymentColumns(tableColumnsArr);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length && errors?.create?.data) {
      onCreateInvoice(errors.create.data.type, {
        ...props,
        data: errors.create.data,
        errors: errors.create,
      });
    }
  }, [errors]);

  const allowed = canCreateClientInvoices();

  useEffect(() => {
    if (setcreateAction)
      setcreateAction({
        // If on dashboard any CSO can initiate creation of payment
        ...(!project || (isCMOrCSOAndHasProjectAcess(project) && !project?.archived && allowed)
          ? {
              visibility: true,
              add: [
                canCreateSaleInvoice()
                  ? {
                      title: "Add New Payment",
                      action: () => onCreateInvoice(INVOICE_TYPE_SALE, props),
                    }
                  : {},

                canCreateCreditNoteInvoice()
                  ? {
                      title: "Add Credit Note",
                      action: () => onCreateInvoice(INVOICE_TYPE_CREDIT_NOTE, props),
                    }
                  : {},
                canCreateCommitmentInvoice()
                  ? {
                      title: "Add Commitment",
                      action: () => onCreateInvoice(INVOICE_TYPE_COMMITMENT, props),
                    }
                  : {},
                canCreateFinalInvoice()
                  ? {
                      title: "Add Final Invoice",
                      action: () => onCreateInvoice(INVOICE_TYPE_FINAL, props),
                    }
                  : {},
              ],
            }
          : { visibility: false }),
      });
  }, [setcreateAction, project, allowed]);

  const handleInvoiceHistory = (invoice) => {
    trackEvent(EVENTS.view_invoice_history, {
      invoice_id: invoice.id,
      invoice_title: invoice.full_title,
      event_category: CATEGORIES.payments,
    });
    openSideBar(<InvoiceHistory selected_invoice={invoice} type="payment" />, { width: "450px" });
  };

  const getTableDisplayValue = (cell) => {
    const invoice = cell?.value;
    const key = `cell-${cell.column.id}-${cell.row.id}`;

    // eslint-disable-next-line default-case
    switch (cell.column.id) {
      case "batch_action": {
        const isBoxChecked = checked?.findIndex((a) => a.id === invoice.id) !== -1;

        return (
          <td key={`batch_action${key}`}>
            {!invoice.paid && (
              <input
                type="checkbox"
                name="check-it"
                className="custom-checkbox"
                data-testid={`checkbox-${key}`}
                id={invoice.id}
                checked={isBoxChecked}
                onClick={() => checkItem(invoice)}
              />
            )}
          </td>
        );
      }
      case "created_at": {
        return (
          <td key={`created_at${key}`} className="nowrap">
            {moment.utc(invoice).format("DD MMM YYYY")}
          </td>
        );
      }
      case "title": {
        return (
          <td className="nowrap" key={`title${key}`}>
            <div className="d-flex flex-column">
              <button
                type="button"
                className="btn-trans text-capitalize line-bottom width-fit-content"
                onClick={() => handleInvoiceHistory(invoice)}
              >
                {invoice?.title}{" "}
              </button>
              <PaymentType className="ms-1">{invoiceType[invoice.type]}</PaymentType>
            </div>
          </td>
        );
      }
      case "exact_invoice_url": {
        return (
          <td className="nowrap" key={`exact_invoice_url${key}`}>
            {invoice ? (
              <a
                className="line-bottom"
                href={`${invoice}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                View in Exact
              </a>
            ) : (
              <b>N/A</b>
            )}
          </td>
        );
      }
      case "client": {
        return (
          <td key={`client${key}`} className="nowrap">
            {cell.value.project?.owner ? cell.value.project?.owner?.display_name : "N/A "}
          </td>
        );
      }
      case "project": {
        return (
          <td className="nowrap" key={`project${key}`}>
            <Link className="line-bottom" to={`/projects/${invoice.project.id}/pay/payments/`}>
              {invoice?.project?.title || "N/A"}
            </Link>
          </td>
        );
      }
      case "invoice": {
        return (
          <td key={`invoice${key}`} className="nowrap">
            {invoice.finalized ? (
              <a
                className="line-bottom"
                href={`${ENDPOINT_INVOICES}${invoice.id}/download/?format=pdf`}
                target="_blank"
                rel="noreferrer"
              >
                {invoice.number}
              </a>
            ) : (
              <b>N/A</b>
            )}
          </td>
        );
      }
      case "amount": {
        const currency = invoice.currency === "USD" ? "$" : "€";

        return (
          <td key={`amount${key}`} className="nowrap">
            {`${invoice.type === INVOICE_TYPE_CREDIT_NOTE ? "-" : ""}${currency}${
              invoice.subtotal
            }`}
          </td>
        );
      }

      case "reminder_count": {
        return (
          <>
            {canSendInvoiceReminder() ? (
              <Tip className="reminder-last-sent-text" key={`reminder_count${key}`}>
                <div
                  data-tooltip={
                    invoice.reminder_count > 0
                      ? moment.utc(invoice.reminder_sent_at).format("DD MMM YYYY")
                      : "No Reminder Sent"
                  }
                >
                  {invoice.reminder_count}
                </div>
              </Tip>
            ) : null}
          </>
        );
      }
      case "due_date": {
        return (
          <td key={`due_date${key}`} className="nowrap">
            {moment.utc(invoice.due_at).format("DD MMM YYYY")}
            {new Date(`${invoice.due_at}`) < new Date() && !invoice.paid && (
              <Icon className="danger" name="exclamation-triangle" />
            )}
          </td>
        );
      }
      case "status": {
        return (
          <td key={`status${key}`} className="nowrap">
            <PaymentStatus invoice={invoice} isSaving={isSaving} />
          </td>
        );
      }
      case "email": {
        const val = cell.value;
        return (
          <td key={`email${key}`} className="nowrap">
            {val === "failed" ? (
              <span className="overdue" style={{ padding: 6 }}>
                Failed
              </span>
            ) : val === "sent" ? (
              <span className="completed" style={{ padding: 6 }}>
                Sent
              </span>
            ) : val === "pending" ? (
              <span className="pending" style={{ padding: 6 }}>
                Pending
              </span>
            ) : null}
          </td>
        );
      }
      case "actions": {
        const cellValue = cell.value;
        return (
          <td key={`actions${key}`} className="cta nowrap">
            <div className="cta-wrapper">
              <ActionItem
                className="line-bottom"
                {...props}
                action={GENERATE_INVOICE_ACTION}
                invoice={cellValue}
              >
                Generate Invoice
                <Icon name="round-arrow-back-ios" className="mx-2" />
              </ActionItem>

              {(showAction(MARK_AS_PAID_ACTION, cellValue) ||
                showAction(ARCHIVE_ACTION, cellValue) ||
                showAction(EDIT_ACTION, cellValue) ||
                showAction(DELETE_ACTION, cellValue) ||
                showAction(APPROVE_ACTION, cellValue) ||
                showAction(REJECT_ACTION, cellValue) ||
                showAction(UNARCHIVE_ACTION, cellValue) ||
                showAction(SEND_FOR_APPROVAL_ACTION, cellValue)) && (
                <StyledButtonDropdown
                  isOpen={open === cellValue.id}
                  toggle={() => toggleAction(cellValue.id)}
                >
                  <DropdownToggle
                    data-testid={`btn-more-${cellValue.id}`}
                    ref={setReferenceElement}
                  >
                    <Icon name="dots-horizontal-small" />
                  </DropdownToggle>

                  {dropDownPortal(
                    <DropdownMenu
                      className="dropdown-menu"
                      ref={setPopperElement}
                      style={styles.popper}
                      {...attributes.popper}
                    >
                      {canMarkInvoiceAsPaid() && (
                        <DropdownActionItem
                          {...props}
                          action={MARK_AS_PAID_ACTION}
                          invoice={cellValue}
                        >
                          <Icon name="circle-check" />
                          &nbsp;&nbsp;&nbsp; Mark as Paid
                        </DropdownActionItem>
                      )}

                      {canSendInvoiceReminder() && (
                        <DropdownActionItem {...props} action={SEND_REMINDER} invoice={cellValue}>
                          <Reminder />
                          &nbsp;&nbsp;&nbsp; Send Reminder
                        </DropdownActionItem>
                      )}

                      {canArchiveInvoice() && (
                        <DropdownActionItem {...props} action={ARCHIVE_ACTION} invoice={cellValue}>
                          <Icon name="archive-outline" />
                          &nbsp;&nbsp;&nbsp; Archive
                        </DropdownActionItem>
                      )}

                      {canUnarchiveInvoice() && (
                        <DropdownActionItem
                          {...props}
                          action={UNARCHIVE_ACTION}
                          invoice={cellValue}
                        >
                          <Icon name="archive-outline" />
                          &nbsp;&nbsp;&nbsp; Unarchive
                        </DropdownActionItem>
                      )}

                      {canSendInvoiceForApproval() && (
                        <DropdownActionItem
                          {...props}
                          action={SEND_FOR_APPROVAL_ACTION}
                          invoice={cellValue}
                        >
                          <Icon name="circle-check" />
                          &nbsp;&nbsp;&nbsp; Send for approval
                        </DropdownActionItem>
                      )}

                      {canApproveInvoice() && (
                        <DropdownActionItem {...props} action={APPROVE_ACTION} invoice={cellValue}>
                          <Icon name="circle-check" />
                          &nbsp;&nbsp;&nbsp; Approve
                        </DropdownActionItem>
                      )}

                      {canRejectInvoice() && (
                        <DropdownActionItem {...props} action={REJECT_ACTION} invoice={cellValue}>
                          <Icon name="cancel" />
                          &nbsp;&nbsp;&nbsp; Reject
                        </DropdownActionItem>
                      )}

                      {canEditInvoice() && (
                        <DropdownActionItem {...props} action={EDIT_ACTION} invoice={cellValue}>
                          <Icon name="circle-edit-outline" />
                          &nbsp;&nbsp;&nbsp; Edit
                        </DropdownActionItem>
                      )}

                      {canDeleteInvoice() && (
                        <DropdownActionItem
                          {...props}
                          action={DELETE_ACTION}
                          invoice={{ ...cellValue, scope, project }}
                        >
                          <Icon name="delete-outline" />
                          &nbsp;&nbsp;&nbsp; Delete
                        </DropdownActionItem>
                      )}
                    </DropdownMenu>,
                  )}
                </StyledButtonDropdown>
              )}
            </div>
          </td>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="section mt-3">
      <PaymentFilters checked={checked} toggleAll={toggleAll} project={project} />

      {!isMakingRequest?.list && invoices.length === 0 ? (
        <SummaryPlaceholder
          className="page-filters-pay-summary"
          description="No payments have been created yet"
        />
      ) : (
        <ReactTable
          scope="payment"
          colDeps={[JSON.stringify(checked), JSON.stringify(paymentColumns)]}
          tableData={tableData(invoices)}
          selectedRows={checked}
          tableColumns={tableColumns}
          currentPage={currentPage}
          count={count}
          getTableDisplayValue={getTableDisplayValue}
          loadPage={onLoadMore}
          pageSize={pageSize}
          isLoading={isMakingRequest?.list}
          noDataMessage="No payments have been created yet"
        />
      )}
    </div>
  );
};

/* --------------------------- Component proptypes -------------------------- */
const proptypes = {
  filter: PropTypes.string,
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.shape({}),
  isSaving: PropTypes.shape({}),
  count: PropTypes.number,
  exportCsv: PropTypes.func,
  onLoadMore: PropTypes.func,
  trackPagination: PropTypes.func,
  currentPage: PropTypes.number,
  setcreateAction: PropTypes.func,
};

Payments.propTypes = proptypes;
Payments.defaultProps = {
  project: null,
};

const Tip = styled.td`
  border-bottom: 1px solid #edf1f7;
  margin-left: -17px;

  *[data-tooltip] {
    position: relative;
  }

  *[data-tooltip]::after {
    content: attr(data-tooltip);

    position: absolute;
    top: -10px;
    right: -10px;
    width: 90px;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    -webkit-transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -ms-transition: opacity 0.15s ease-in-out;
    -o-transition: opacity 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out;

    font-size: 12px;
    line-height: 16px;
    background: #edf1f7;
    padding: 2px 2px;
    border-radius: 3px;
    border: 1px solid #c0c0c0;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
  }

  *[data-tooltip]:hover::after {
    opacity: 1;
  }

  &.reminder-last-sent-text {
    padding-left: -24px !important;
  }
`;

const PaymentType = styled(Badge)`
  color: rgb(33, 150, 83) !important;
  background: rgba(33, 150, 83, 0.04) !important;
  border: 1px solid rgba(33, 150, 83, 0.04) !important;
  font-size: 10px !important;
  width: fit-content;
`;

export default Payments;
